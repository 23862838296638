.App {
    background-color: #ffffff;
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-direction: column;
}

.screenReaderOnly {
    position: absolute;
    margin: -1px 0 0 -1px;
    padding: 0;
    display: block;
    width: 1px;
    height: 1px;
    font-size: 1px;
    line-height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    outline: 0;
}

main {
    margin-bottom: 24px;
}
